import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import {
    AccordionPanel,
} from 'reaxl';
import {
    FilterCheckboxes,
} from 'reaxl-filters';

import getFilterTitle from '@/utilities/getFilterTitle';

import {
    srpFiltersDuck,
} from '@/ducks/srp';

import FilterPlaceholder from './FilterPlaceholder';

/**
*  PriceRatingFilter presents the user with the ability to filter using price rating/ Deal type attributes
*/
function PriceRatingFilter({
    onOptionChange = () => {},
    onOptionsClear = () => {},
    handleShowExpansion = () => {},
}) {
    const priceRatingValues = useSelector(srpFiltersDuck.selectors.getPriceRatingValues);
    const priceRatingOptions = useSelector(srpFiltersDuck.selectors.getPriceRatingOptions);

    const filtersErrors = useSelector(srpFiltersDuck.selectors.getFiltersErrors);
    const filterValues = {
        dealType: priceRatingValues,
    };

    const filterName = 'dealType';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(priceRatingOptions).length !== 0;

    // expand the panel if active options inside of it
    useEffect(() => {
        if (priceRatingValues.length) {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceRatingValues.length]);

    const title = getFilterTitle('Best Deals', priceRatingValues);

    return (
        <AccordionPanel
            eventKey={filterName}
            data-cmp="PriceRatingFilter"
            title={title}
            contentPadding={5}
        >
            { !showFilter && <FilterPlaceholder uniqueKey={filterName} />}
            { showFilter && (
                <FilterCheckboxes
                    key="filterCheckboxes"
                    filter={priceRatingOptions}
                    onOptionChange={onOptionChange}
                    onOptionsClear={onOptionsClear}
                    values={filterValues}
                    errors={filtersErrors}
                    withAccordion={false}
                />
            )}
        </AccordionPanel>
    );
}

export default PriceRatingFilter;
